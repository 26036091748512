export function htmxFunctions() {

  document.body.addEventListener('htmx:onLoad', (target) => {
    bootstrap.init(target);
  });

  // htmx request headers hx-* not allowed by Access-Control-Allow-Headers in preflight response
  // https://github.com/bigskysoftware/htmx/issues/779#issuecomment-1019373147

  // document.addEventListener('htmx:configRequest', (evt) => {
  //   evt.detail.headers = [];
  // });
}
